import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import appConfig from './AppConfig';
import { Flex } from '@aws-amplify/ui-react';
import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';


//1.8a

function PaypalTime(props) { 

  
  const apiName = 'bpcapi'; // replace this with your api name.
  const path = '/items'; //replace this with the path you have configured on your API
  
  const apiNamepaypal = 'bpcapipaypal'; // replace this with your api name.
  //const path2 = '/items'; //replace this with the path you have configured on your API

  const [state4, setState4] = useState("--");
  const [state2a, setState2a] = useState("--");
  

function stateChange2a(f) {
  setState2a(f);
  props.funct2a(f); 
};



function stateChange4(f) {
  setState4(f);
  props.funct(f);
  
};



function notify(note) {toast.success(note)};



function addTimeTest() {

  addTimeButton('TEST000000')
};


    function addTimeButton(data) {



//alert('data' + data);





        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        //myHeaders.append("Content-Type", "text/plain");
        
        var raw = JSON.stringify({"uname": props.uname,"act": "addTime_"+ appConfig.apiname,"dat": data});
    

      var myInit = {
        body: raw,
        headers: {
          'Content-Type': 'application/json'}, 
          redirect: 'follow',
      };
      API.post(apiNamepaypal, path, myInit)
      .catch((error) => {
        alert(appConfig.appname + " PAYPAL API ERROR: " + error.response) 
      });
        //alert("Payment Successful");
        notify('Payment Successful');
        stateChange4("0");

       if (props.upay === "0" ) {
        //notify('2a1');
        stateChange2a("1");
      
       } else {
        //notify('2a0');
        stateChange2a("0");
        
       }

        
      }
    
      // production "client-id": "AT-bDu0WdCz2cOExWJ6H6jJg9HhVJ8kw5I15q9rLvbFPqfNgUhdnzO_7ZdIMVzMB19Fs0q_cD9oaCaCb", 
      //sandbox "client-id": "AbZVVQnt3H3Pb3LAFG_632bi-B4sy7Hmn8cd3b9Z9yJMYOl0QRblGoXbSnp6SELPE7WQY4fVC6H_Uqwp", 
      //  <button style={{ border: "none",color: "black", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d", color: "white" , display: "inline-block", fontSize: "12px", height: "21px", width: "150px" }} onClick={addTimeButton}>TEST PAYPAL</button>


      const OrderButtonWrapper = () => {
        
        return (
        <>Add $5.00 Time
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
                          purchase_units: [{
                              amount: {
                                  value: '5.00'
                              }
                          }]
                      });
          }}
          style={{
            layout: "horizontal",
            tagline: false,
            height: 45,
            color: "gold"
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(function (details) {
                //alert( details.id);
                 //alert('Transaction completed by ' + details.payer.name.given_name);
                 addTimeButton(details.id) 
          });

           }}
        /></>);
      };






       return (<>
<Flex 
 direction="column"
 textAlign="center"
 alignItems="center"
maxWidth = "150px"
minHeight = "96px"
gap = "0px"
>




       <PayPalScriptProvider
    options={{
        "client-id": "AT-bDu0WdCz2cOExWJ6H6jJg9HhVJ8kw5I15q9rLvbFPqfNgUhdnzO_7ZdIMVzMB19Fs0q_cD9oaCaCb", 
        
        components: "buttons",
        "data-namespace": "paypalOrder"
    }}
>
    <OrderButtonWrapper />
</PayPalScriptProvider>




{props.uname !== "support@burnerpc.com" ? (<></> ) : (<> <button style={{ border: "none", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d", color: "white" , display: "inline-block", fontSize: "12px", height: "21px", width: "150px" }} onClick={addTimeTest}>TEST PAYPAL</button></> )}



  
</Flex>    

</>
);

}

export default PaypalTime;









