import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import appConfig from './AppConfig';






function PaypalSubs(props) { 


}

export default PaypalSubs;









