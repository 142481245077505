import React, { useState } from 'react';
import appConfig from './AppConfig';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";



function StaticIP(props) { 


 
  const [state, setState] = useState("--");
  



function stateChange(f) {
  setState(f);
  props.funct1(f);
  props.uip = f;
};













function addSIP(data) {
  var raw = JSON.stringify({"uname": props.uname,"act": "addsip_"+ appConfig.apiname,"dat": "yes"});
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
    body: raw,
    redirect: 'follow'
  };
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
  
  alert("Static IP Address Updated");
  stateChange("yes")
}

function noSIP(data) {
  var raw = JSON.stringify({"uname": props.uname,"act": "addsip_"+ appConfig.apiname,"dat": "no"});
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
    body: raw,
    redirect: 'follow'
  };
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
  
  alert("Static IP Address Updated");
  stateChange("no")
}




const SubscriptionButtonWrapper = () => {


  //var tmppid = "P-1LM627509M073663NMPIGKPY"  


  return (<>
  
  Static IP Address - $3.65/mo
  <div style={{textAlign: "center", margin: "0 auto", maxWidth: "150px" }}>
  <PayPalButtons
  
    createSubscription={(data, actions) => {
      return actions.subscription
        .create({
          plan_id: "P-" ,////////////////////////////////////////////////////////////////////////////////////
        });
    }}
    style={{
      label: "subscribe",
      layout: "horizontal",
      tagline: false,
      color: "white",
      height: 45,
     }}
    onApprove={(data, actions) => {
      return actions.subscription.capture(  addSIP(data)  );
     }}
  />
  </div>

  </>);
}












       return (<>



<PayPalScriptProvider
			options={{
				"client-id": "AT-bDu0WdCz2cOExWJ6H6jJg9HhVJ8kw5I15q9rLvbFPqfNgUhdnzO_7ZdIMVzMB19Fs0q_cD9oaCaCb", 
				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			<SubscriptionButtonWrapper /> 


		</PayPalScriptProvider>


       <br></br>
      No Static IP Address - $0.00
      <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={noSIP}>SKIP</button>
      <br></br><br></br>
      TEST Static IP Address
      <button style={{ border: "none", color: "white", backgroundColor: "#900003", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={addSIP}>TEST</button>
     
       </>
);

}

export default StaticIP;









