import React, { useState } from 'react';
import appConfig from './AppConfig';

//1.6a


function Systems(props) { 


 
  const [state4, setState4] = useState("--");
  const [state4a, setState4a] = useState("--");
  



function stateChange4(f) {
  setState4(f);
  props.funct4(f);
  
};

function stateChange4a(f) {
  setState4a(f);
  props.funct4a(f);
  
};



function goSys1() {
  //Open help/support page in new window
  // window.open("https://gsalpha.com/tos");
  
  const input = "a4";
  const input2 = "a";
  //type1
  const input3 = "1";
    classButton(input, input2, input3)  
}

function goSys2() {
  //Open help/support page in new window
  // window.open("https://gsalpha.com/tos");
  
  const input = "a4";
  const input2 = "b";
  //type0
  const input3 = "0";
    classButton(input, input2, input3)  
}



function classButton(data,data2,data3) {
  var raw = JSON.stringify({"uname": props.uname,"act": "class_"+ appConfig.apiname,"dat": data + data2});
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
    body: raw,
    redirect: 'follow'
  };
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
  
  alert("System Updated");
  stateChange4(data);stateChange4a(data3)
}






       return (<>
       
       
       GS1 RPro V520 Gaming PC $0.65/hr
      <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goSys2}>2.80Ghz 4vCPU 16GB-RAM HDD</button>
      <br></br><br></br>
       GS2 RPro V520 Gaming PC $1.15/hr
      <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goSys1}>2.80Ghz 8vCPU 32GB-RAM SSD</button>
      
      

       </>
);

}

export default Systems;









