
  
  import { createChatBotMessage } from "react-chatbot-kit";
  
  const config = { 
   botName: "Help ChatBot",
  //botName: "Bot",
   // initialMessages: [createChatBotMessage("Hello, I am a ChatBot that understands the BurnerPC Help Files.  What can I help you with?")],
   initialMessages: [createChatBotMessage("Hello, I am the Help ChatBot for BurnerPC.  What may I assist you with today?")],
   customComponents: {
    // Replaces the default header
   header: () => <div style={{ backgroundColor: 'black',color: '#FB8B24',  padding: "5px" }}>Help ChatBot</div>
   // Replaces the default bot avatar
//   botAvatar: (props) => <MyAvatar {...props} />,
   // Replaces the default bot chat message container
//   botChatMessage: (props) => <MyCustomChatMessage {...props} />,
   // Replaces the default user icon
//   userAvatar: (props) => <MyCustomAvatar {...props} />,
   // Replaces the default user chat message
//   userChatMessage: (props) => <MyCustomUserChatMessage {...props} />
 },
    customStyles: {
      botMessageBox: {
        backgroundColor: "#0F4C5C",
      },
      chatButton: {
        backgroundColor: "#0F4C5C",
      },
    },
  }
  
  export default config