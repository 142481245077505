import React, { useState, useEffect, useRef} from 'react';
import appConfig from './AppConfig';
import logo from './785.svg';
import { API } from 'aws-amplify'
//import PaypalTime from "./PaypalTime";
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Line } from 'rc-progress';
import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
//import { Amplify } from 'aws-amplify';
//import awsExports from './aws-exports';
//Amplify.configure(awsExports);


//v1.8b

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}




  
//function numberWithCommas(x) {
//  x = x.toString();
//  var pattern = /(-?\d+)(\d{3})/;
//  while (pattern.test(x))
//      x = x.replace(pattern, "$1,$2");
//  return x;
//}


function dispTime(a){
  var h = Math.trunc(a/60);
  var m = a % 60;
  if (h === 0) {
    var hours = '';
  } else if (h === 1) {
     hours = '1hr ';
  } else {
     hours = h + 'hrs ';
  };
  var minutes = m + "min";
  return hours + minutes ;
}











function StatusTime(props) { 

  

  const [fact2, setFact2] = useState("");




  const [open, setOpen] = React.useState(false);
  
  const handleClickToOpen = () => {
    setOpen(true);
  };
  
  const handleToClose = () => {
    setOpen(false);
  };
  
  //const [open2, setOpen2] = React.useState(false);
  
//  const handleClickToOpen2 = () => {
//    setOpen2(true);
//  };
  
//  const handleToClose2 = () => {
//    setOpen2(false);
//  };
  

const [state, setState] = useState("0");
const [state2, setState2] = useState("0");

const [sysOpStat1, setSysOpStat1] = useState("--");
const [sysOpStat2, setSysOpStat2] = useState("--");
const [sysOpStat3, setSysOpStat3] = useState("--");
const [sysOpStat4, setSysOpStat4] = useState("--");
const [sysOpStat5, setSysOpStat5] = useState("--");
const [sysOpStat6, setSysOpStat6] = useState("--");
  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState("--");
  const [tic, setTic] = useState(0);

  //function notify(note) {toast.success(note);};
  function notify(note) {toast.info(note)};
  //function enotify(note) {toast.error(note)};
  function enotify(note) {toast.warning(note)};
  
 // const [prog, setProg] = useState(0);
 // const [lprog, setLprog] = useState(0);
  
const [uname, setUname] = useState("");

const [loader, setLoader] = useState("");



   const apiName = 'bpcapi'; // replace this with your api name.
const path = '/items'; //replace this with the path you have configured on your API



  function fetchFact2() {
    //alert('ff2 ' + props.value);


    
    var raw = JSON.stringify({"uname": props.value,"act": "status_"+ appConfig.apiname});
    var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };

     API.post(apiName, path, myInit)
     .then((response) => {
      setFact2(response)
      })
       .catch((error) => {
        alert(appConfig.appname + " STATUS-8 API ERROR: " + error.message + ' ' + error.response) 
      })
    };




    
  var tmpcnt = 0;
 // if (state !== "1") {
 //   tmpcnt = 1000;
 // } else 
  
//  if (props.upay !== state2 ) { 
//    setState2(props.upay);
//    setState('0');
//    tmpcnt = 1000;
//  } else { biz
    //default 10000
   tmpcnt = 5000;
 // };
  



  useInterval(() => {
   //setUname(props.value);

   if (props.value === "--") {
    //skip unmae & fetch set
  } else {

if (props.ustat === "--") {
//ERROR in APP.JS
//alert('skipped fetch')
} else {

    fetchFact2(); 


    if (props.value === "rkz@toughguys.biz") { goSysOp() }


};
//alert("ST-F2");
 //   if (uname === "") {
 //     setUname(props.value)
//} ;
  };

   // if (prog > 0 ) {
   //   setProg(prog+5)
//
 //  }

// if (prog !== lprog) {
//    setLprog(prog +3 );
//    setProg(prog+3);
// };


    if (counter === 10) {
      setCounter(1);
      if (tic === 100000) { 
        setTic(1);
      } else {
        setTic(tic + 1);
      }
    } else {
      setCounter(counter + 1);
    }
  }, tmpcnt);




if (state === props.upay) {
//alert("eq")
}
else
{
 // alert("noteq");
  fetchFact2(); 
  setState(props.upay)
};


  function startButton() {
    var raw = JSON.stringify({"uname": props.value,"act": "start_"+ appConfig.apiname});
    var apiName = 'bpcapi'; // replace this with your api name.
    var path = '/items'; //replace this with the path you have configured on your API
    var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " START API ERROR: " + error.message + ' ' + error.response) 
   // .then((response) => {setFact2(response)
    }
   );


//    var raw = JSON.stringify({"uname": uname,"act": "start_"+ appConfig.apiname});
 //   var requestOptions = {
 //     method: 'POST',
 //     headers: {
  //      'Content-Type': 'application/json',
  //      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
 //     },
 //     body: raw,
 //     redirect: 'follow'
 //   };
 //     fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
  }
  
  
  function stopButton() {
    var raw = JSON.stringify({"uname": props.value,"act": "stop_"+ appConfig.apiname});
       var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " STOP API ERROR: " + error.message + ' ' + error.response) 
   // .then((response) => {setFact2(response)
    }
   );



  }
  



  function goStartM() {
    var raw = JSON.stringify({"uname": props.value,"act": "startM_"+ appConfig.apiname});
    var apiName = 'bpcapi'; // replace this with your api name.
    var path = '/items'; //replace this with the path you have configured on your API
    var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " START-M API ERROR: " + error.message + ' ' + error.response) 
   // .then((response) => {setFact2(response)
    });
    alert("STARTING DEFAULT SI")
  };

  
function goButton() {
 //alert('button ' + fact2);
  //////////////////////////////////////

if (fact2 === "") {

  //var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );

  var tmpstat = props.ustat;
  var tmpbank = props.ubank;
  var tmpustanding = props.ustanding;
  var tmplaststart = props.laststart

} else {

   tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );

   tmpstat = tmpstr.split("!")[0];
   tmpbank = tmpstr.split("!")[1];
   tmpustanding = tmpstr.split("!")[5];
   tmplaststart = tmpstr.split("!")[20];

};
  //var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );

 //var tmpstat = tmpstr.split("!")[0];
 //var tmpbank = tmpstr.split("!")[1];
 //var tmpustanding = tmpstr.split("!")[5];

    


  if( tmpustanding === "enabled" ) {

 



    if (tmpstat === 'Stopped') {
      var tmpblock = "";
      var lsdate2 = new Date(tmplaststart);
      var nowdate2 = new Date();
      var diff = nowdate2.getTime() - lsdate2.getTime();
      var diff2 = parseInt(diff/60000,10) ;
      if (diff2 < 5 ) {
        //Block start with cooldown period
        tmpblock = "cooldown"         
      };

     if (tmpblock === "cooldown") { 
      enotify("Please allow Cooldown to complete.") 
      } else if (parseInt(tmpbank) < 10 ) {
        enotify("Less than 10 min remain.  Please add more time to continue.")  
      } else {
  
     setLoader("starting");
        startButton();
 //       setProg(1)
     //     notify('Creating Cloud PC');
      };

    } else if (tmpstat === 'Running' ) {
  
     setLoader("stopping");
      stopButton();
 //     notify('Destroying Cloud PC');
    }

  } else {
      ///
  }

  
 

};





function goSysOpUpdate() {

  var raw = JSON.stringify({"uname": props.value,"act": "sysopupdate_"+ appConfig.apiname});
  var myInit = {
 body: raw,
 headers: {
   'Content-Type': 'application/json'}, 
   redirect: 'follow',
              };
API.post(apiName, path, myInit)
.catch((error) => {
   alert(appConfig.appname + " SYSOP UPDATE API ERROR: " + error.message + ' ' + error.response) 
                  });
  alert("Update Command Sent");
//.then((response) => {
  //doSysOp(response)
//                  }) 
};



function goSysOp() {

  var raw = JSON.stringify({"uname": props.value,"act": "sysopstatus_"+ appConfig.apiname});
  var myInit = {
 body: raw,
 headers: {
   'Content-Type': 'application/json'}, 
   redirect: 'follow',
              };
API.post(apiName, path, myInit)
.catch((error) => {
   alert(appConfig.appname + " SYSOP API ERROR: " + error.message + ' ' + error.response) 
                  })
.then((response) => {
  doSysOp(response)
                  }) 
};


function doSysOp(res) {




var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
var tmpRun = tmpstr.split("!")[0];
var tmpHot = tmpstr.split("!")[1];
var tmpCold = tmpstr.split("!")[2];
var tmpTime = tmpstr.split("!")[3];
var tmpsd = tmpstr.split("!")[4];
var tmpSdur = tmpsd.split(".")[0];


//if (counter2 === "--") {
//  setCounter2("=---")
//} else if (counter2 === "=---") {
//  setCounter2("-=--")
//} else if (counter2 === "-=--") {
//  setCounter2("--=-")
//} else if (counter2 === "--=-") {
//  setCounter2("---=")
//} else {
//  setCounter2("=---")
//}

//if (counter2 === "--") {
//setCounter2("\\")
//}else if (counter2 === "\\") {
//setCounter2("|")
//}else if (counter2 === "|") {
//  setCounter2("/")
//} else {
//setCounter2("--")
//}

//if (counter2 === "--") {
//  setCounter2("|")
//} else if (counter2 === ":") {
//  setCounter2("|")
//} else {
//  setCounter2(":")
//}

//var date1 = new Date(tmpTime);
//var date1 = new Date("3/24/2023 4:00:00 pm");
var date1 = new Date(tmpTime);
var date2 = new Date();
//alert(date1.toDateString + " " + tmpTime);
var diff = date2.getTime() - date1.getTime();
var diff2 = parseInt(diff/60000,10) 
if (diff2 > 60 ) {
  var tmpSys = "WARNING"
} else {
  tmpSys = "OK"
};


setCounter2( date2.toLocaleString() );


//alert(diff);
setSysOpStat1( "SYSTEM " + tmpSys  )
setSysOpStat2( "USR: " + tmpRun )
setSysOpStat3( "HSP: " + tmpHot  )
setSysOpStat4( "CSP: " + tmpCold  )
setSysOpStat5( "STIME: " + tmpSdur  )
setSysOpStat6(  "LAST UPDATE: " + diff2 + "min" )
};






function goBlank() {
  //Open help/support page in new window
  // window.open("https://gsalpha.com/tos");
}




function downloadTxtFile() {
 
const currentDate = new Date();
const day = currentDate.getDate();
const month = currentDate.getMonth();
const year = currentDate.getFullYear();
// show in specific format
//let datecode = (month+1) + '-' + day + '-' + year ;
let datecode = "connect";

  //var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
  //var tmpuip = tmpstr.split("!")[1];
  if (fact2 === "") {
    var tmpuip = props.uip;
    var tmpupass = props.upass;
  } else {
    var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
    tmpuip = tmpstr.split("!")[17];

   
    var tmp = tmpstr.split("!<<<<")[1];
    var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
  };

  const element = document.createElement("a");
  const txt = "auto connect:i:1\nfull address:s:" + tmpuip +"\nusername:s:Administrator";

 //navigator.clipboard.writeText(tmpupass);

  const file = new Blob([txt], {
    type: "text/plain"
  });
  element.href = URL.createObjectURL(file);
  element.download = appConfig.apiname + "_" + datecode + ".rdp";
  document.body.appendChild(element);
  element.click();


};







function downloadTxtFile2() {
 
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  // show in specific format
  //let datecode = (month+1) + '-' + day + '-' + year ;
  let datecode = "connect";
  
    if (fact2 === "") {
      var tmpuip = props.uip;
      var tmpupass = props.upass;
    } else {
      var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
      tmpuip = tmpstr.split("!")[17];   
      var tmp = tmpstr.split("!<<<<")[1];
      var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
    };
  
    const element = document.createElement("a");
       
     const txt1 = "cmdkey /generic:TERMSRV/" + tmpuip + " /user:Administrator /pass:" + "\"" + tmpupass + "\"";
     const txt2 = "mstsc /v:"+tmpuip;
     const txt3 = "cmdkey /delete:" + tmpuip;
    const txt = txt1 + "\n" + txt2 + "\n" + txt3;  

    const file = new Blob([txt], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = appConfig.apiname + "_" + datecode + ".cmd";
    document.body.appendChild(element);
    element.click();
  
  
  };
  
  




  function copyClip() {
 
   
      if (fact2 === "") {
   //     var tmpuip = props.uip;
        var tmpupass = props.upass;
      } else {
        var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
   //     tmpuip = tmpstr.split("!")[17];
    
       
        var tmp = tmpstr.split("!<<<<")[1];
        var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );
      };
    
     navigator.clipboard.writeText(tmpupass);
    
    
    };
    
    




  if (fact2 === "") {

    //fetchFact2(); 
//alert("called FF")
    var tmpstr = props.fact.substring( props.fact.indexOf("|") + 1,props.fact.lastIndexOf("~") );
    //alert("using App Fact " + props.fact2)


  } else {
   var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
//alert("using ST Fact ")
  };

//alert("3"+props.fact+" -=- "+fact2)

  
//tmpUspin=true;
// tmpUshow='1';  
  
 
 
 var tmpUspin=false;
  

 var tmpuSD = "";
 var tmpBClass = 'BK-button';
 var tmpDClass = 'BK-button';
 var tmpDClass2 = 'App-bank';

 var tmpDstatus = "";
 var tmpUshow = "0";
 var tmpDbutton = "";
 var tmpUstatus = "";
   var tmpUdur = "";
 var tmpUbank = "";
 
 var tmputos = props.utos;
 var tmpustanding = props.ustanding;
 var tmpureg = props.ureg;
 var tmputype = props.utype;
 var tmpuclass = props.uclass;
 var tmpuip = props.uip;

 var tmpstat = props.ustat;
 var tmpstat2 = tmpstat;
 var tmpstate = props.ustate;
var tmpbank = props.ubank;
var tmpdet = props.udet;
var tmpstate2 = tmpstate;
var tmpdur  = props.udur;
var tmpucpu = props.ucpu;
var tmpusdt = props.usdt;
var tmpupass = props.upass;
var tmplaststart = props.laststart

if (tmputos === "--") {

} else 

{

if (tmpstr !== "") { 
//alert("not blank");


   tmpstat = tmpstr.split("!")[0];
//alert("f3" + fact2);
 // if (tmpstat.includes("AWS")) {
  //  alert(tmpstat)
 // }
  tmpstat2 = tmpstat;

  tmpdur = tmpstr.split("!")[2];
    tmpbank = tmpstr.split("!")[1];
    tmpdet = tmpstr.split("!")[3];

    tmpstate = tmpstr.split("!")[4];
   tmpstate2 = tmpstate;
   //var tmpustanding = tmpstr.split("!")[5];
   

//  if (props.utos === "--") {
     tmputos = tmpstr.split("!")[7];
     tmpustanding = tmpstr.split("!")[5];
     tmpureg = tmpstr.split("!")[10];
     tmputype = tmpstr.split("!")[8];
     tmpuclass = tmpstr.split("!")[12];
     tmpuip = tmpstr.split("!")[17];
     tmplaststart = tmpstr.split("!")[20];
  //} else {
///////////////
  //}

  
   

   
 
   //var tmpuclass = tmpstr.split("!")[12];
  
    tmpucpu = tmpstr.split("!")[13];
     tmpusdt = tmpstr.split("!")[18];
     //tmpupass = tmpstr.split("!")[20];
     
    var tmp = tmpstr.split("!<<<<")[1];
    var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );


  } else {
    //tmpstr was empty
  // alert("blank")
  };
    
  




   if (tmpdet === "Backing Up" ) {
    tmpDstatus = "Backing Up"
  } else if (tmpdet === 'Verifying') {
    tmpDstatus = "Backing Up"
  } else if (tmpdet === 'Checking Status') {
    tmpDstatus ="Checking Status"
  } else {
    tmpDstatus = tmpstat
  };

    if (appConfig.burnOn === "true") {
      if (tmpstate === "starting" && tmpstat !== "Running") {
        if (tmpdet ==="Checking Status5" ) { 
          tmpDstatus="Waiting for Startup"
        } else if  (tmpdet ==="Checking Status6")  {
          tmpDstatus="Waiting for Startup"
        } else {
          tmpDstatus="Creating PC"
        }
      } else if (tmpstate ==="stopping" && tmpstat !== "Stopped") {
        tmpDstatus="Destroying PC11"
      } else {

        tmpDstatus = tmpstat
      
      }
   
    };

   

    




  //////////////////////////
  if (appConfig.ignoreStanding === "true") {
    tmpustanding = "enabled";
  }
  
  if (appConfig.ignoreClass === "true") {
     tmpuclass = "set";
    }
///////////////////////////////  


    if (appConfig.showSys === "true") {

      if (tmpuclass === 'a4') { 
        if (tmputype === '1') { 
          var tmpSys = ' GS1'   
        } else {
           tmpSys = ' GS2'
        }
      
      } else if (tmpuclass === 'g4ad') {
        tmpSys='S4';

      } else {
      
        if (tmpuclass === 't3m') {
            
              tmpSys = " S1";
        } else if ( tmpuclass === 't3l' ) {

          if (tmpucpu === '1') {tmpSys = ' S2' } else {tmpSys = ' S3'}


         } else {


          tmpSys = " " + tmpuclass.toUpperCase() +'-'  + tmpucpu

          }
      };
      



      if (tmpureg === 'us-west-2') {tmpSys = tmpSys + '-W'} else if (tmpureg === 'us-east-1') {tmpSys = tmpSys + '-E'} else {tmpSys = tmpSys + 'u'}


    } else {
        tmpSys = ""      
    };


    

    if (tmpstat2 === "Start Failed" ) {
      //ERROR OVERRIDE

      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='0';  
      tmpUbank="";
      tmpuSD =   "";
      //tmpstate = "starting";
      //alert(tmpstat);
      //tmpstat = "DATA1B";
      tmpDstatus="Start Error (" + tmpdet +  ")";
      tmpUbank="Admin has been notified";
      tmpUdur="";
      tmpDbutton='ERROR' ;
      tmpUspin='false';

    } else if (tmpstat2 === "Stop Failed" ) {
        //ERROR OVERRIDE
  
        tmpBClass = 'PR-button';
        tmpUspin=true;
        tmpDClass = 'App-processing';
        tmpUshow='0';  
        tmpUbank="";
        tmpuSD =   "";
        //tmpstate = "starting";
        //alert(tmpstat);
        //tmpstat = "DATA1B";
        tmpDstatus="Stop Error (" + tmpdet +  ")";
        tmpUbank="Admin has been notified";
        tmpUdur="";
        tmpDbutton='ERROR' ;
        tmpUspin='false';

    } else {

      var tmpLoader = "";
      var lsdate = new Date(tmplaststart);
      var nowdate = new Date();
      var diff = nowdate.getTime() - lsdate.getTime();
      var diff2 = parseInt(diff/60000,10) ;
      if (diff2 < 5 && tmpstat2 === 'Stopped' ) {
        //Block start with cooldown period
        var tmpLoader = "cooldown"     
            
      };
    


    if (loader === "starting" ) {
    
      if (tmpstat2 === "Starting" || tmpstat2 === "Running") {
        //clear loader
        setLoader("");
        tmpLoader = "" ;
        if (tmpstat2 === "Running" ) {tmpP = 80}
        
   //loader override
     // tmpDbutton='LD1-PROCESSING';
      //tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "starting";
      //alert(tmpstat);
      tmpstat = "DATA1B";
      if (appConfig.burnOn === "true") {tmpDstatus="Creating PC"} else {tmpDstatus="Starting"};
      tmpUshow='1';  
      tmpUdur="";

     
      } else {
      
      //loader override
     // tmpDbutton='LD1-PROCESSING';
      //tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "starting";
      //alert(tmpstat);
      tmpstat = "DATA1A";
      if (appConfig.burnOn === "true") {tmpDstatus="Creating PC"} else {tmpDstatus="Starting"};
      //tmpDstatus = "";
      tmpUshow='1';  
      tmpUdur="";

      };
        
  
    } else if (loader === "stopping") {
  
      if (tmpstat2 === "Stopped") {
        //clear loader
        setLoader("")
        tmpLoader = "" ;

            //loader override
    //  tmpDbutton='LD2-PROCESSING';
     // tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "stopping";
      //alert(tmpstat);
      tmpstat = "DATA2B";
      if (appConfig.burnOn === "true") {tmpDstatus="Destroying PC"} else {tmpDstatus="Stopping"};
      tmpUshow='1';  
      tmpUdur="";

      } else {

      //loader override
    //  tmpDbutton='LD2-PROCESSING';
     // tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "stopping";
      //alert(tmpstat);
      tmpstat = "DATA2A";
      if (appConfig.burnOn === "true") {tmpDstatus="Destroying PC"} else {tmpDstatus="Stopping"};
      tmpUshow='1';  
      tmpUdur="";
      };
  
     } else {

      if (tmpLoader === "cooldown") { 
        //loader ovveride cooldown
        tmpstat = "cooldown";
        


      };


     };




/////////////////////////////////////////Main 




  if (tmpstate === "starting" ) {
    //Starting State 
    tmpUshow = '1'
    if (tmpstat === "Stopped" ) {
      // skip
      tmpDstatus ="ERROR";
    } else if (tmpstat === 'Running') {
 //     tmpUbutton='STOP';
      tmpDbutton='STOP' ;
      //tmpUbcolor='#900003';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-running';
      tmpBClass = 'SP-button';
      if (appConfig.burnOn === "true") {tmpDbutton='DESTROY PC'};
      tmpUstatus=tmpstat; 
      //tmpUdet="";
      //tmpUbank=tmpbank + " min";
      //var tmpSD = dispTime(Number(tmpusdt) - Number(tmpdur));
      var tmpSD = dispTime(tmpusdt);
      tmpUbank=dispTime(tmpbank) + " / " ;
      tmpuSD =   tmpSD;
      //tmpUmessage="";
      tmpUdur=" (" + dispTime(tmpdur) + ")";
      
      tmpUspin =false;
     // tmpUmessage=tmpitype
    } else if (tmpstat === "") {
      // skip
      tmpDstatus ="ERROR";
    } else {
  //    tmpUbutton='PROCESSING';
      tmpDbutton='PROCESSING';
     // tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
     // if (appConfig.burnOn === "true") {tmpDstatus="Creating PC1"};
      tmpUstatus=tmpstat;
      //tmpUdet=" - " + tmpdet;
     // tmpUbank=tmpbank + " min";
     tmpUbank=dispTime(tmpbank);
    // tmpuSD="SD: " + dispTime(tmpusdt);
      tmpUspin=true;
      //tmpUmessage="";    
      tmpUdur="";
     // tmpUmessage=tmpitype
    };

    
  } else if (tmpstate === "stopping") {
    //Stopping State
//alert("stp");
    if (tmpstat === "Stopped" ) {


      if (tmputos === "1") {


        if (tmpustanding ==="enabled" && tmpureg !== "not set" ) {
        //enabled account 
   //     tmpUbutton='START';
        tmpDbutton='START' + tmpSys;
       // tmpUbcolor='green';
        //tmpUbcolor2='#4CAF50';
        tmpDClass = 'App-stopped';
        tmpBClass = 'ST-button';
        if (appConfig.burnOn === "true") {tmpDstatus='Ready';tmpDbutton='CREATE PC'} else {tmpDstatus= tmpstat};
        tmpUstatus=tmpstat; 
        //tmpUdet="";
        //tmpUbank=tmpbank + " min";
        tmpUbank=dispTime(tmpbank) ;
    //    tmpuSD="SD: " + dispTime(tmpusdt);
        tmpUspin=false;
        //tmpUmessage="";
        
        tmpUdur="";
        tmpUshow='1';
        //tmpUmessage=tmpitype
      } else if (tmpureg ==="not set") {
        //Region not set
        tmpDbutton='DISABLED';
        //tmpUbcolor='#4d4d4d';
        tmpBClass = 'PR-button';
        //tmpUbcolor2='yellow';
        tmpDClass = 'App-processing';
        tmpUstatus=tmpstat; 
       // tmpUbank="Please select a Datacenter Location"
       tmpDstatus="Please select a Datacenter Location";
        //tmpDstatus="";
        tmpDClass2 = 'App-processing';
        //tmpUbank=tmpbank + " min";
       
        tmpUspin=false;
        tmpUshow='0';
        tmpUdur=""
       } else {

          if (tmpuclass ==="not set") { 
         //Class not set
        tmpDbutton='DISABLED';
        //tmpUbcolor='#4d4d4d';
        //tmpUbcolor2='yellow';
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
        tmpUstatus=tmpstat; 
        tmpUbank="Please select a System"
        tmpDstatus="";
        tmpDClass2 = 'App-processing';
        //tmpUbank=tmpbank + " min";
         tmpUspin=false;
        tmpUshow='0';
        tmpUdur=""


          } else {



            if (tmpuip ==="not set") { 
              //Class not set
             tmpDbutton='DISABLED';
             //tmpUbcolor='#4d4d4d';
             tmpBClass = 'PR-button';
             //tmpUbcolor2='yellow';
             tmpDClass = 'App-processing';
             tmpUstatus=tmpstat; 
             tmpUbank="Please select an IP Address Option"
             tmpDstatus="";
             tmpDClass2 = 'App-processing';
          //   tmpUbank=tmpbank + " min";
              tmpUspin=false;
             tmpUshow='0';
             tmpUdur=""
     
     
               } else {

          //disabled account
    //    tmpUbutton='DISABLED';
          tmpDbutton='DISABLED';
          //tmpUbcolor='#4d4d4d';
          tmpBClass = 'PR-button';
          //tmpUbcolor2='#4CAF50';
          tmpUstatus=tmpstat; 
           //tmpUdet="";
       //    tmpUbank=tmpbank + " min";
            tmpUspin=false;
            //tmpUmessage="Please pay storage fee to activate account";
           //tmpUmessage = "--";
       //    tmpUbcolor2='yellow';
           tmpUshow='0';
            //tmpDstatus="-";
            tmpUbank="Please subscribe via PayPal to activate account";
            tmpDstatus="";
            tmpDClass2 = 'App-processing';
           tmpUdur=""
          }
        }

        };
       } else if (tmputos ==="0") {
        //TOS not accepted
   //     tmpUbutton='PROCESSING';
        tmpDbutton='DISABLED';
        //tmpUbcolor='#4d4d4d';
        tmpBClass = 'PR-button';
        //tmpUbcolor2='#4CAF50';
        //tmpUbcolor2='yellow';
        tmpDClass = 'App-processing';
        tmpUstatus=tmpstat; 
       // tmpDstatus="";
       // tmpUbank="Please rewiew and accept the Terms Of Service";
       tmpDstatus="Please rewiew and accept the Terms Of Service";
        tmpDClass2 = 'App-processing';
       // tmpUdet="";
   //     tmpUbank=tmpbank + " min";
        tmpUspin=false;
        //tmpUmessage="Please read & accept the 'Terms Of Service'";
        //tmpUmessage="--";
        tmpUshow='0';
        //tmpDstatus="-";
        tmpUdur=""

       } else {
        alert("ERROR - missing tos value (" + tmputos + ")")

      };

     } else if (tmpstat === 'Running') {
     // skip
      tmpUshow='1'
     } else if (tmpstat === "") {
      // skip
//      tmpUshow='0'
//      tmpDbutton='PROCESSING';
      //tmpUbcolor='#4d4d4d';
//      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
//      tmpDClass = 'App-processing';
//      tmpUstatus="Loading";
//      tmpDstatus="Loading Account";
      //tmpUdet=" - " + tmpdet;
    //  tmpUbank="";
//      tmpUspin=true;
     // tmpUmessage="";
//      tmpUshow='1';  
 //     tmpUdur="";



      
     } else if (tmpstat === "Bad Account Name") { 
      alert('Bad Account ' + props.value + " " + tmpstat)
 //     tmpUshow='0'
 //     tmpDbutton='PROCESSING';
      //tmpUbcolor='#4d4d4d';
 //     tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
//      tmpDClass = 'App-processing';
//      tmpUstatus="Loading";
      tmpDstatus="Loading Account " + props.value + "/" + uname;
      //tmpUdet=" - " + tmpdet;
   //   tmpUbank="";
 //     tmpUspin=true;
     // tmpUmessage="";
  //    tmpUshow='1';  
  //    tmpUdur="";

     } else if (tmpstat === "No Account Found") {




            //    tmpUbutton='DISABLED';
      tmpDbutton='DISABLED';
      //tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      //tmpUdet="";
    //  tmpUbank=tmpbank + " min";
      tmpUspin=false;
      tmpDstatus="No Account Found. Please request access"
      //tmpUmessage="Please request access at www.gsalpha.com";
      //tmpUmessage = "--";
      tmpUshow='1';
      tmpUdur="";
      tmpUshow='0'

    } else if (tmpstat === "cooldown") {




      //    tmpUbutton='DISABLED';
          tmpDbutton='DISABLED';
          //tmpUbcolor='#4d4d4d';
          tmpBClass = 'GR-button';
          //tmpUbcolor2='yellow';
          tmpDClass = 'App-processing';
          tmpUstatus=tmpstat; 
          //tmpUdet="";
          
          tmpUspin=false;
          tmpDstatus="Cooldown " + (5 - diff2) + "min"
          //tmpUmessage="Please request access at www.gsalpha.com";
          //tmpUmessage = "--";
          tmpUshow='0';
          tmpUdur="";
          
          tmpUbank=dispTime(tmpbank);
        




     } else {
   //   tmpUbutton='PROCESSING';
      tmpDbutton='PROCESSING';
      //tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
      if (appConfig.burnOn === "true") {tmpDstatus="Destroying PC"};
      tmpUstatus=tmpstat;
      //tmpUdet=" - " + tmpdet;
     // tmpUbank=tmpbank + " min";
     tmpUbank=dispTime(tmpbank);
    // tmpuSD="SD: " + dispTime(tmpusdt);
      tmpUspin=true;
     // tmpUmessage="";
      tmpUshow='1';  
      tmpUdur="";
      //tmpUmessage=tmpitype

     };
  
   
   

  
  } else {
  //  alert("error")
  };


}


}

   
if (tmpUbank === "0min") {
  tmpUbank="ADD TIME to Get Started!";
  tmpDstatus ="Welcome to BurnerPC";
  tmpBClass = 'GR-button';
  tmpDClass = 'App-processing';
  tmpDClass2 = 'App-processing';
  }

  var tmpP = 0;

if (tmpstate === "starting") {
    tmpP = 0;

  if (tmpdet === "Booting" ) {
    tmpP = 10;
  } else if (tmpdet === "Checking Status1") {
    tmpP = 20;
  }  else if (tmpdet === "Checking Status2") { 
    tmpP = 30;
  }  else if (tmpdet === "Checking Status3") { 
    tmpP = 50;
  } else if (tmpdet === "Checking Status4") { 
    tmpP = 60;
  } else if (tmpdet === "Checking Status5") { 
    tmpP = 80; 
  }  else if (tmpdet === "Checking Status6") { 
     tmpP = 100;
    }

//setProg(tmpP)


} else if (tmpstate === "stopping") {
  tmpP = 0;

 if (tmpdet === "Destroying System2") {
  tmpP = 50;
}  else if (tmpdet === "Destroying System3") { 
  tmpP = 90};




};


if (tmpUshow === '0') {
  var tmpMH = '0px'
 
} else {
   tmpMH = '134px'
}


if (tmpP > 0) {
  var tmpUspin2=true;
 } else {
  tmpUspin2=false;

 }


 if (props.value === "rkz@toughguys.biz") {
  var tmpHideSysOp = false;
 } else {
  tmpHideSysOp = true;
 }
 


    return (


      
        <>
          
       <div style={{ color: 'white' , fontWeight: "normal", textAlign: "center", maxWidth: '400px' , marginLeft: '20px', marginRight: '20px', marginBottom: '10px', marginTop: '10px', minHeight:'72px', fontSize: '14px'}}> 
       <span className = {tmpDClass} style={{fontWeight: "normal",fontSize: '24px'}}> {tmpDstatus}{tmpUdur}<br></br></span>
       <span className = {tmpDClass2} style={{fontSize: '24px'}}>{tmpUbank}</span><span className = 'App-SDT' style={{fontSize: '24px'}}>{tmpuSD}</span>

        {tmpustanding ==="enabled" && tmpureg !== "not set"  ? (

       tmpUshow === '0' ? (<></>):(<>
       
       
        </>
       )
  ) :(< ></>)}

</div> 
  
         
<span hidden = {appConfig.debugHide}> tic({tic}-{counter}) tos({tmputos}) tmpustanding({tmpustanding}) tmpureg({tmpureg}) tmpdet({tmpdet})</span>


<Flex
         direction="column"
         textAlign="center"
         alignItems="center"
        gap =  {appConfig.bGap}
        minHeight={tmpMH}
        >

      {tmputos === "1" ? (
      
      tmpustanding ==="enabled" && tmpureg !== "not set"  ? (<>
        
          
          <button className = {tmpBClass} style={{ border: "none",  display: "inline-block", marginTop: "10px", fontSize: "14px", height: "45px", width: "150px" }} onClick={goButton}>{tmpDbutton}</button>


        </>):(<></>)
      
      
      ):(<></>) }
         



      { tmpUspin === true ? ( <><Flex 
      
      direction="column"
      //marginLeft='10px'
      //marginRight='10px'
     // wrap='wrap'
     // gap='10px'
 //     marginTop='15px'
  //    marginBottom='calc(15px + 6px)'
  paddingTop="15px"
  paddingBottom="9px"
     textAlign="center"
     alignItems="center"
     justifyContent= "center"
     gap = '0px'
     minHeight={appConfig.minHeightD }>     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
      

      {tmpUspin2 === true ? ( <><Line percent={tmpP} strokeWidth={3} strokeColor="#D3D3D3" /></> ) : (<></>)}
      
      </> ) : ( 
        
  
        tmputos === "1" ? (<>
        
          {tmpUstatus === "Running" ? ( <>  
  
        <button className = 'RDP-button' style={{ marginBottom: "27px", border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={handleClickToOpen}>RDP CONNECT</button> 
        </>
          ) : (        
          
          
            tmpustanding ==="enabled" && tmpureg !== "not set"  ? ( <>
             <button style={{paddingBottom: "71px", border: "none", color: "black", backgroundColor: "black", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goBlank}>BLANK</button>
                
          </>) : ( <></>)
                
                
                 )}
  
  
        </>
  
        ) : (<></>) 
  
  
        
         ) }

</Flex>

 <span hidden = {appConfig.debugHide}>{tmpstat}-2({tmpstat2})-{tmpstate}-2({tmpstate2}) Loader({loader}) upay({props.upay}) reg({tmpureg}) tos({tmputos}) <br></br>
 <button onClick={notify}>Notify</button>
 </span>
       
 
 <div stlye={{}}>

      <Dialog open={open} onClose={handleToClose}>
        <DialogTitle>{"RDP CONNECTION INFO"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <span style={{color: "black"}}>IP Address: {tmpuip}<br></br> User: Administrator <br></br>Password: {tmpupass} <br></br><br></br>Click 'Copy' to copy the password to the clipboard.<br></br>Click 'Download' to download the RDP Connection File.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
        <Button onClick={copyClip} 
                  color="primary" autoFocus
                 // variant="outlined"
                   >
            <Flex 
            //paddingRight="10px"
            > Copy</Flex>
          </Button>
        <Button onClick={downloadTxtFile} 
                  color="primary" autoFocus
                 // variant="outlined"
                   >
            <Flex 
            //paddingRight="10px"
            > Download</Flex>
          </Button>
          <Button onClick={handleToClose} 
                  color="primary" autoFocus
                //  variant="outlined"
                 >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>

   

    <div hidden = {tmpHideSysOp} className = 'sys-op'>
      
        BURNER PC STATUS <br></br><br></br>
        {sysOpStat1}<br></br><br></br>
        {sysOpStat2}<br></br>
        {sysOpStat3}<br></br>
        {sysOpStat4}<br></br><br></br>
        {sysOpStat5}<br></br><br></br>
        {sysOpStat6}<br></br>
        {counter2}
        
        <br></br><br></br>
        <button className = 'RDP-button' style={{ marginBottom: "27px", border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goSysOpUpdate}>UPDATE</button> 
        <br></br>
        <button className = 'RDP-button' style={{ marginBottom: "27px", border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goStartM}>START SI</button> 
        
        </div>

       
         </>
      );
    



    
}
    export default StatusTime;

