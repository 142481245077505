//v1.8a
const appConfig = {
    "ver": " ",
    "appname": "BurnerPC",  
    "webtitle": "BurnerPC Portal", 
    "apiname": "bpc", 
    "websitename": "burnerpc.com", 
    "showSys": "false",
    "maxcred":2000,
    "minHeight":'544px',  //non-gsa
    "minHeightB":'394px', //non-gsa - sign out inistal offset
    "minHeightC":'168px', //non-gsa
    "minHeightD":'38px', //non-gsa - progress logo gap
    "bGap":"6px",
    "debugHide":true,
   //"minHeight":'562px', //GSA
   //"minHeightB":'427px', //GSA
   //   "minHeightD":'72px', //GSA
    //VGS
    "useSIP":"false",
    //BurnerPC
    "ignoreStanding": "true",
    "ignoreClass": "true",
    "burnOn": "true",
};

export default appConfig;
