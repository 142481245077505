
import React, { Fragment } from 'react';
import { API } from 'aws-amplify'
import appConfig from './AppConfig';




class ActionProvider {
    constructor(createChatBotMessage, setStateFunc) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
    };


    
    greet(content,uname) {


  

      const apiName = 'bpcapi';
      const path = '/items'; 
      const apiNamechat = 'bpcapichat'; // replace this with your api name.
//const path2 = '/items'; //replace this with the path you have configured on your API
    
      const greetingMessage = this.createChatBotMessage("Let me think about that...");
      this.updateChatbotState(greetingMessage);

      if (content.trim() !== "") {


      var raw = JSON.stringify({"uname": uname,"act": "chat_"+ appConfig.apiname, "dat": content});
      var myInit = {
        body: raw,
        headers: {
          'Content-Type': 'application/json'}, 
          redirect: 'follow',
      };
       
      API.post(apiNamechat, path, myInit)
      .catch((error) => {
        console.log("error: " + error.response)})
       .then((response) => {
        var tmp1 = JSON.parse(response.replace('response:',''));
        var tmp2 = JSON.stringify(tmp1.choices);
       var tmp3 = tmp2.replace(/\[/g,'');
        var tmp4 = tmp3.replace(/\]/g,'');
        
        var tmp5 = JSON.parse(tmp4);
        var tmp6 = tmp5.message.content;
        var tmp7 = JSON.stringify(tmp6)
        var tmp8 = tmp7.replace(/\\"/g,'');
        var tmp9 = tmp8.replace(/\\n/g,'  ');

        const greetingMessage = this.createChatBotMessage(tmp9);

        this.updateChatbotState(greetingMessage);
        });     
         
      } else {
        var tmpStr = "I'm sorry.  I didn't understand the question.";
        const greetingMessage = this.createChatBotMessage(tmpStr);
        this.updateChatbotState(greetingMessage);

    
      }
      



    };


  
 
    

   // updateChatbotState(message) {
   updateChatbotState(message) {
   
  // NOTE: This function is set in the constructor, and is passed in      // from the top level Chatbot component. The setState function here     // actually manipulates the top level state of the Chatbot, so it's     // important that we make sure that we preserve the previous state.
   
      
     this.setState(prevState => ({
          ...prevState, messages: [...prevState.messages, message]
      }))
    }
  }
  
  



  export default ActionProvider
  
