import React, { useState, useRef } from 'react';
import PaypalSubs from "./PaypalSubs";
import PaypalTime from "./PaypalTime";
import Regions from "./Regions";
import Systems from "./Systems";
import StaticIP from "./StaticIP";
import appConfig from './AppConfig';
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
import { API } from 'aws-amplify'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';




//v1.8b

function Body(props) { 

  const apiName = 'bpcapi'; // replace this with your api name.
  const path = '/items'; //replace this with the path you have configured on your API


  const valueRef = useRef('') //creating a refernce for TextField Component

  const sendValue = () => {
      //alert(valueRef.current.value); //on clicking button accesing current value of TextField and outputing it to console ;

      var input = valueRef.current.value;



      if (alertPrompt === 'Enter New Time') {
//SD Time

  if (isNaN(input))
    {
    enotify("Invalid number");
    } else { 
      sdtButton(input) ;
      //alert("Auto shut down time updated");
      notify('Auto Shut Down Time updated');
    }



      } else {
//SMS Alerts
      var str = input.toUpperCase();
            if ((str) === "DISABLE") {
              phoneButton(str)  
            } else {
              
              if (isNaN(input))
              {
              enotify("Invalid number");

              } else {

                if (input.length === 12 ) {

                  if ( input.includes("+1")) {

                      //allow number change     
                      phoneButton(input)  

                      } else {
                          enotify("Cancelled - Missing Country Code")
                       }
              
                      } else {
                        enotify("Cancelled - Incorrect Length")
                      }

                    }
                    }
             
               ;
                  }
      setOpen(false);
  }



  const [state, setState] = useState("--");
  const [state2, setState2] = useState("--");
  const [state2a, setState2a] = useState("0");
  const [state3, setState3] = useState("--");
  const [state4, setState4] = useState("--");
  const [state4a, setState4a] = useState("--");
  const [state5, setState5] = useState("--");
  const [uopt, setUopt] = useState("0");
  const [uopt2, setUopt2] = useState("0");
  const [uopt3, setUopt3] = useState("0");

 
  const [ureg, setUreg] = useState("--");
  const [uclass, setUclass] = useState("--");
  const [utype, setUtype] = useState("--");
  const [uip, setUip] = useState("--");

  const [alertMessage, setAlertMessage] = useState("--");
  const [alertMessage2, setAlertMessage2] = useState("");
  const [alertTitle, setAlertTitle] = useState("--");
  const [alertPrompt, setAlertPrompt] = useState("--");
  const [open3, setOpen3] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open, setOpen] = React.useState(false);


  const handleClickToOpen3 = () => {
    setOpen3(true);
  };
  
  const handleToClose3 = () => {


    setOpen3(false);

     window.open("https://" + appConfig.websitename +"/help");

  };

  const handleClickToOpen2 = () => {
    setOpen2(true);
  };
  
  const handleToClose2 = () => {
    setOpen2(false);
  };
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    
    
    setOpen(false);
   
  };


  function notify(note) {toast.success(note);};
  function enotify(note) {toast.error(note);};

 function stateChange(f) {
        setState(f);
        props.funct(f);
        props.key = "";
  };

  function stateChange2(f) {  
    setState2(f);
    props.funct2(f);
    props.key2 = "";
};

function stateChange2a(f) {  
  setState2a(f);
  props.funct2a(f);
  props.key2 = "";
};


function stateChange3(f) {
  //alert('SC');
  if ((appConfig.burnOn === "true") ) {
    setAlertMessage("Your BurnerPC account is now active! The HELP page will automaticly open to assit with your first use.");
    setAlertTitle('Account Setup Complete');
    handleClickToOpen3()
    //window.open("https://" + appConfig.websitename +"/help");
  }
  setState3(f);
  props.funct3(f);
  props.key2 = "";
};

function stateChange4(f) {
  setState4(f);
  props.funct4(f);
  props.key2 = "";
};

function stateChange4a(f) {
  setState4a(f);
  props.funct4a(f);
  props.key2 = "";
};

function stateChange5(f) {
  setState5(f);
  props.funct5(f);
  props.key2 = "";
};

function stateChange6(f) {
  setState5(f);
  props.funct6(f);
  props.key2 = "";
};


function dispTime(a){
  var h = Math.trunc(a/60);
  var m = a % 60;
  if (h === 0) {
    var hours = '';
  } else if (h === 1) {
     hours = '1hr ';
  } else {
     hours = h + 'hrs ';
  };
  var minutes = m + "min";
  return hours + minutes ;
}



  function goTOS() {
    //Open help/support page in new window
     window.open("https://" + appConfig.websitename + "/tos");
  }
  
  
  function goAcceptTOS() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"uname": props.uname,"act": "tos_"+ appConfig.apiname});
  
  //  var requestOptions = {
  //   method: 'POST',
  //   headers: {
  //    'Content-Type': 'application/json',
  //    'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
  //  },
  //   body: raw,
  //   redirect: 'follow'
 //  };
     // make API call with parameters and use promises to get response
    //fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
    
    var myInit = {
   body: raw,
   headers: {
     'Content-Type': 'application/json'}, 
     redirect: 'follow',
 };
 API.post(apiName, path, myInit)
 .catch((error) => {
  alert(appConfig.appname + " TOS API ERROR: " + error.message + ' ' + error.response) 
});
    
   
 // if (appConfig.burnOn="true") {
  //  alert("Welcome to Burner PC! Your account is now active.  Please use the ADD TIME Button next to get started!");
  // } else {
   // alert("TOS Accepted");
   //notify('TOS Accepted');
  // }
   stateChange2("1")
  };


  
  function goChatBot() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"uname": props.uname,"act": "cb_"+ appConfig.apiname});
  
  //  var requestOptions = {
  //   method: 'POST',
  //   headers: {
  //    'Content-Type': 'application/json',
  //    'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
  //  },
  //   body: raw,
  //   redirect: 'follow'
  // };
     // make API call with parameters and use promises to get response
   // fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
   var myInit = {
    body: raw,
    headers: {
      'Content-Type': 'application/json'}, 
      redirect: 'follow',
  };
  API.post(apiName, path, myInit)
  .catch((error) => {
    alert(appConfig.appname +  " CB ERROR: " + error.message + ' ' + error.response)  
  });


  if (props.ucb === "1") {
    stateChange6("0")
  } else {
    stateChange6("1")
  }
   
  };




  function goOpt3() {

    var raw = JSON.stringify({"uname": props.uname,"act": "status_"+ appConfig.apiname});
//  var requestOptions = {
//      method: 'POST',
//      headers: {
//              'Content-Type': 'application/json',
//            'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
//              },
//      body: raw,
//      redirect: 'follow',
//   };
 

   
     //fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
     var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
   .then((responce) => GetData2(responce))
   .catch((error) => {
    alert(appConfig.appname +  " STATUS2 API ERROR: " + error.message + ' ' + error.response) 
  });

  }


  function GetData2(responce) {

   var tmpstr = responce.substring( responce.indexOf("|") + 1,responce.lastIndexOf("~") );
  //var tmpstat = tmpstr.split("!")[0];
  var tmpdur = tmpstr.split("!")[2];
  tmpdur = tmpdur.split(" ")[0];
  var tmpbank = tmpstr.split("!")[1];
//alert(tmpbank)
  //alert(tmpdur)
  var tmpnum = (Number(tmpbank) - Number(tmpdur));

    if (tmpnum < appConfig.maxcred) { 
      setUopt2("1");

    } else { 

      setAlertMessage("Your current Time Bank is at " + dispTime(tmpnum) +  ".  \nIt must be below the limit of " + dispTime(appConfig.maxcred) + " before purchasing more Time.");
      setAlertTitle('Maximum Time Reached');
      handleClickToOpen2()
        //alert("Current Credits at " + String(tmpnum) +  ".  \nMust be below the limit of " + dispTime(appConfig.maxcred) + " before purchasing more credits");

     };

  }


  function goOpt1() {

    var raw = JSON.stringify({"uname": props.uname,"act": "status_"+ appConfig.apiname});

   var myInit = {
    body: raw,
    headers: {
      'Content-Type': 'application/json'}, 
      redirect: 'follow',
  };
  API.post(apiName, path, myInit)
    .then((responce) => GetData(responce))
    .catch((error) => {
      alert(appConfig.appname + " STATUS3 API ERROR: " + error.message + ' ' + error.response) 
    });
  }

  function GetData(responce) {

    var tmpstr = responce.substring( responce.indexOf("|") + 1,responce.lastIndexOf("~") );
    var tmpstat = tmpstr.split("!")[0];
    if (tmpstat === "Stopped") { 
      setUopt("1");
      setUopt3("0");
     } else { 
     // alert("Options are disabled while Cloud PC is Running");
     setUopt("1");
      setUopt3("1");
     };
  }

  
  function goOpt2() {
    //Open help/support page in new window 
    // window.open("https://gsalpha.com/help");
    setUopt("0");
    //statusButton()
    // alert("disabled");
  }

  function goOpt4() {
    //Open help/support page in new window 
    // window.open("https://gsalpha.com/help");
    setUopt2("0");
    //statusButton()
    // alert("disabled");
  }

  function goOpt6() {
    //Open help/support page in new window 
    // window.open("https://gsalpha.com/help");
    setUopt3("0");
    setUopt("0");
    //statusButton()
    // alert("disabled");
  }


  function goHelp() {
    //Open help/support page in new window 
     window.open("https://" + appConfig.websitename +"/help");
  }
  

  function phoneButton(phone) {
    var raw = JSON.stringify({"uname": props.uname,"act": "phone_"+ appConfig.apiname,"dat": phone});

    var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
    .catch((error) => {
      alert(appConfig.appname + " PHONE API ERROR: " + error.message + ' ' + error.response) 
    });;
    if (phone === "DISABLE") {
      //alert("Phone Number Disabled")
      notify('SMS Alerts DISABLED');
      goOpt2()
    } else {
      //alert("Phone Number Updated")
      notify('SMS Alerts Updated');
      goOpt2()
    }
  }


  
function goReset() {
  //Open help/support page in new window 
  // window.open("https://gsalpha.com/help");

  


  const input = prompt("Enter 'yes' below to confirm full data erase / cloud pc reset");
  if (input === null) { 
   // alert("Reset Cancelled")
  } else if( input === 'yes' ) {
    resetButton();
    alert("Data will be reset at next Start Up");
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }


}


function resetButton() {
  var raw = JSON.stringify({"uname": props.uname,"act": "reset_"+ appConfig.apiname});

 var myInit = {
  body: raw,
  headers: {
    'Content-Type': 'application/json'}, 
    redirect: 'follow',
};
API.post(apiName, path, myInit)
.catch((error) => {
  alert(appConfig.appname + " RESET API ERROR: " + error.message + ' ' + error.response)  
});;

}

  function goAlert() {


    var raw = JSON.stringify({"uname": props.uname,"act": "status_"+ appConfig.apiname});

   var myInit = {
    body: raw,
    headers: {
      'Content-Type': 'application/json'}, 
      redirect: 'follow',
  };
  API.post(apiName, path, myInit)
     
     .then((responce) => GetData3(responce))
     .catch((error) => {
      alert(appConfig.appname +  " STATUS4 API ERROR: " + error.message + ' ' + error.response) 
    });

    }

     function GetData3(responce) {

      var tmpstr = responce.substring( responce.indexOf("|") + 1,responce.lastIndexOf("~") );
      //var tmpstat = tmpstr.split("!")[0];
      var tmpuphone = tmpstr.split("!")[11];



      setAlertMessage('Current SMS Phone Number: ' + tmpuphone );
      setAlertMessage2('Enter a new number using the format +1XXX5555555 or enter \'disable\' to turn the feature off.');
      setAlertPrompt('Phone Number');
      setAlertTitle('SMS Alerts');
      handleClickOpen();



  }






  
function goRollback() {
  const input = prompt("Enter 'yes' below to confirm cloud pc rollback to last backup");
  if (input === null) { 
   // alert("Rollback Cancelled")
  } else if( input === 'yes' ) {
    rollbackButton();
    alert("STOP the cloud PC to complete the Rollback");  
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////  
  }
}

function rollbackButton() {
  var raw = JSON.stringify({"uname": props.uname,"act": "rollback_"+ appConfig.apiname});

 var myInit = {
  body: raw,
  headers: {
    'Content-Type': 'application/json'}, 
    redirect: 'follow',
};
API.post(apiName, path, myInit)
.catch((error) => {
  alert(appConfig.appname +  " ROLLBACK API ERROR: " + error.message + ' ' + error.response) 
});
}


function goSDT() {


  
  //const input = prompt("Please enter the desired shut down time in minuites.", "1440" );



  setAlertMessage('Please enter the desired shut down time in minuites.');
  setAlertMessage2('')
  setAlertPrompt('Enter New Time (min)');
  setAlertTitle('Auto Shut Down Time');
  handleClickOpen();


}

function sdtButton(sdt) {

  var raw = JSON.stringify({"uname": props.uname,"act": "sdt_"+ appConfig.apiname,"dat": sdt});

 var myInit = {
  body: raw,
  headers: {
    'Content-Type': 'application/json'}, 
    redirect: 'follow',
};
API.post(apiName, path, myInit)
.catch((error) => {
  alert(appConfig.appname +  " SDT API ERROR: " + error.message + ' ' + error.response) 
});
  goOpt2()

}



  function goBlank() {};


if (state === "--" ) {
  var tmpustanding = props.ustanding;
} else if (state === "enabled") {
  tmpustanding = "enabled"
};

if (state2 === "--") {
  var tmputos = props.utos
} else if (state2 === "1") {
  tmputos = "1"
};

if (state3 === "--") {
  var tmpureg = props.ureg
} else  {
  tmpureg = state3
};

if (state4 === "--") {
  var tmpuclass = props.uclass
} else  {
  tmpuclass = state4
};

if (state4a === "--") {
  var tmputype = props.utype
} else  {
  tmputype = state4a
};

if (ureg === "--") {
  tmpureg = props.ureg
} else if (ureg !== "--") {
  tmpureg = ureg;
};

if (uclass === "--") {
  tmpuclass = props.uclass
} else if (uclass !== "--") {
  tmpuclass = uclass;
};

if (utype === "--") {
  tmputype = props.utype
} else if (utype !== "--") {
  tmputype = utype;
};

if (appConfig.useSIP === 'true'){
  if (uip === "--") {
    var tmpuip = props.uip
  } else if (uip !== "--") {
    tmpuip = uip;
  };
} else {
  tmpuip = 'no';
};




if (appConfig.ignoreStanding === "true") {

  if (tmpureg === "not set") {
    tmpustanding = "disabled";
  } else {
    tmpustanding = "enabled";
  }

};

if (appConfig.ignoreClass === "true") {
   tmpuclass = "set";
  };

  if (appConfig.burnOn === "true") {
    tmpuip = "set";
   var tmph4 = true;
    var tmph5 = false;
  } else {
    tmph4 = false;
    tmph5 = true;

   };

if (uopt2 === '1' ) {
  var tmph1 = false;
  var tmph2 = true;

  var tmph3 =  true;
  
} else {
  tmph1= true;
  tmph2 = false;

  tmph3 = false;
  
};

if (tmpustanding ==="enabled") {
  var tmph2a = false;
} else{ 
  tmph2a = true;
}


if (props.ucb === "1") {
  var tmpCB = "SHOW CHATBOT";

} else {
   tmpCB = "HIDE CHATBOT";

}


var tmpATClass = 'AT-button';




return (

    




<div style={{ textAlign: "center",  maxWidth: "300px" , minHeight: appConfig.minHeightC }}>
<Flex
  direction="column"
  //marginLeft='10px'
  //marginRight='10px'
 // wrap='wrap'
 // gap='10px'
 marginTop='20px'
 // marginBottom='10px'
 textAlign="center"
 alignItems="center"
 gap = {appConfig.bGap}
>






		{tmputos === "1" ?     (<>



     
     

      {tmpustanding ==="enabled" ? ( 
     





     uopt === '0' ? (
     <>  



 <span hidden = {tmph1}> 
 
        
    <PaypalTime uname={props.uname} upay={props.upay} funct={setUopt2}  funct2a={stateChange2a} />
    </span>
    <span hidden = {tmph2}> 
   
      <button className = {tmpATClass} style={{ border: "none", display: "inline", fontSize: "14px", height: "45px", width: "150px" }} onClick={goOpt3}>ADD TIME</button>
      </span>

</>

     ) : (  uopt3 === '0' ? (
     
     
    <>

<span hidden = {tmph4}> 
     <button className = 'RT-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goReset}>RESET PC</button>
     </span>
     <span hidden = {tmph5}> 
     <button  className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goChatBot}>{tmpCB}</button>

      </span>
    </> 
     
     ) :(<>
     
     <span hidden = {tmph4}> 
      <button className = 'RB-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goRollback}>ROLLBACK PC</button>
      </span>

      <span hidden = {tmph5}> 
      <button  className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goChatBot}>{tmpCB}</button>
      </span>

     </>)

     )




     ) :( 
     
     tmpustanding ==="disabled" ? (  


      tmpureg ==="not set" ? ( <>
      
      
      
      <Regions uname={props.uname} ureg={props.ureg} funct3b={stateChange3} funct1b={stateChange}/>
      
      </> ) : (
      
      
      tmpuclass ==="not set" ? ( <>
      
      
      
      <Systems uname={props.uname} funct4={stateChange4} funct4a={stateChange4a} uclass={tmpuclass} utype={tmputype}/>
      
      
      
      </>) : (


          tmpuip ==="not set" ? ( <><StaticIP uname={props.uname} funct1={stateChange5} /> </> ) : (<><PaypalSubs uname={props.uname} uclass={tmpuclass} utype={tmputype} funct1b={stateChange} /> </>)

    
      )
        
        )



 




      ) :( <></>)

     
     )

  }







        </>) : (
        
          tmputos === "0" ?     (<><br></br><br></br>
        
      <button  className = 'Misc-button' style={{ marginBottom: '10px' , border: "none", color: "white", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goTOS}>VIEW TERMS</button>
            <button className = 'SP-button' style={{ border: "none", color: "white",  fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goAcceptTOS}>ACCEPT TERMS</button>
            <br></br>

         </>) : (<></>)



        )    
        }




<span hidden = {tmph3}> 

      {tmputos === "1" ?     (
      
      

      tmpustanding ==="enabled" && tmpureg !== "not set"  ? ( 
        
          uopt ==="0" ? ( 
        <>
        <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         gap = {appConfig.bGap}
        >
          <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goHelp}>HELP</button>
        
        <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }}  onClick={goOpt1}>OPTIONS</button></Flex>
        </>
          ) :( <>
          
          {uopt3 ==="0" ? (<>
          
            <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         gap = {appConfig.bGap}
        >
            <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goAlert}>SMS ALERTS</button>

            <><button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }}  onClick={goOpt2}>EXIT OPTIONS</button></> 

</Flex>
          </>) : (<>
          
            <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         gap = {appConfig.bGap}
        >
           <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }}  onClick={goSDT}> SET SD TIME</button>
          
              <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goOpt6}>EXIT OPTIONS</button>

          </Flex>
          </>)} 
          
          
          </>)



        ) : ( <></>)





      
      ):(<></>)}
      
      </span>


      <span hidden = {tmph1}> 
           
        <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goOpt4}>CANCEL PAYPAL</button>
        
      </span>

      </Flex>

     <span hidden = {appConfig.debugHide} >tus{tmpustanding} tureg{tmpureg} tutos{tmputos} tuclass{tmpuclass} tuip{ tmpuip} uopt{uopt} tmph3{String(tmph3)}</span> 




     <div stlye={{}}>

<Dialog open={open2} onClose={handleToClose2}>
  <DialogTitle>{alertTitle}</DialogTitle>
  <DialogContent>
    <DialogContentText>
    <span style={{color: "black"}}>{alertMessage}</span>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleToClose2} 
            color="primary" autoFocus>
      Close
    </Button>
  </DialogActions>
</Dialog>
</div>


<div stlye={{}}>

<Dialog open={open3} onClose={handleToClose3}>
  <DialogTitle>{alertTitle}</DialogTitle>
  <DialogContent>
    <DialogContentText>
    <span style={{color: "black"}}> {alertMessage} </span>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleToClose3} 
            color="primary" autoFocus>
      Close
    </Button>
  </DialogActions>
</Dialog>
</div>





<Dialog open={open} onClose={handleClose}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {alertMessage2 !=="" ? (<><span style={{color: "black"}}>{alertMessage}</span><br></br><br></br><span style={{color: "black"}}>{alertMessage2}</span></>) : (<><span style={{color: "black"}}>{alertMessage}</span></>)}
          </DialogContentText>
          <br></br>
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label={alertPrompt}
            type="test"
            fullWidth
            variant="standard"
            inputRef={valueRef}
          />
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button> 
          <Button onClick={sendValue}>Update</Button>
        </DialogActions>
      </Dialog>





</div>






)

}

export default Body;