
class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  



        parse(message) {
       //     const lowerCaseMessage = message.toLowerCase()
       var uname = "user"
        //    if (lowerCaseMessage.includes("hello")) {
              this.actionProvider.greet(message,uname)
        //    }




    }
  }
  
  export default MessageParser;
  
