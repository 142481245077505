import React, { useState } from 'react';
import appConfig from './AppConfig';
import { API } from 'aws-amplify';
//1.8a


function Regions(props) { 


  const apiName = 'bpcapi'; // replace this with your api name.
  const path = '/items'; //replace this with the path you have configured on your API


  //const [state, setState] = useState("--");
 
  const [state3, setState3] = useState("--");
    
//  const [alertMessage, setAlertMessage] = useState("--");
 // const [alertMessage2, setAlertMessage2] = useState("");
//  const [alertTitle, setAlertTitle] = useState("--");
 // const [alertPrompt, setAlertPrompt] = useState("--");
 // const [open2, setOpen2] = React.useState(false);
 // const [open, setOpen] = React.useState(false);


function stateChange3(f) {
  setState3(f);
  props.funct3b(f);
  props.ureg = f;
};





  function goUSW() {
    //Open help/support page in new window
    // window.open("https://gsalpha.com/tos");
    
    var input = "us-west-2";
      regButton(input)  
  }

  
  function goUSE() {
    //Open help/support page in new window
    // window.open("https://gsalpha.com/tos");
    
   // var input = "us-east-1";
    alert("US-EAST Temporarily DISABLED for this round of testing");
      //regButton(input)  
  }




  function regButton(data) {
    var raw = JSON.stringify({"uname": props.uname,"act": "reg_" + appConfig.apiname,"dat": data});
//    var requestOptions = {
//      method: 'POST',
//      headers: {
//        'Content-Type': 'application/json',
//        'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
//      },
//      body: raw,
//      redirect: 'follow'
//    };
   // fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)  
   var myInit = {
    body: raw,
    headers: {
      'Content-Type': 'application/json'}, 
      redirect: 'follow',
  };
  API.post(apiName, path, myInit)
    .catch(err => alert(err));
   //  if ((appConfig.burnOn === "true") ) {
      //window.open("https://" + appConfig.websitename +"/help");
      //alert("Welcome to Burner PC! Your account is now active. Please use the ADD TIME Button next to get started!");

  //  } else {
     // alert("Region Updated");
  //  };
    //must be last due to code stopping
    stateChange3(data); 
  }




       return (<>
       
       <br></br><br></br>
       <button className = 'Misc-button' style={{ marginBottom: '10px' , border: "none", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goUSW}>US WEST</button>

       <button className = 'Misc-button' style={{ border: "none", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goUSE}>US EAST</button>
      

       </>
);

}

export default Regions;


