import React, { useState, useEffect } from 'react';
import './App.css';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import logo from './785.svg';
import StatusTime from "./StatusTime";
import Body from "./Body";
import appConfig from './AppConfig';
import { Auth,API } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import background from "./main_background.png";
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import config from './config';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';  
import awsExports from './aws-exports';
Amplify.configure(awsExports);

//1.8a
  


function App({signOut}) {
//  const [fact2, setFact2] = useState("");



const apiName = 'bpcapi'; // replace this with your api name.
const path = '/items'; //replace this with the path you have configured on your API


  const [uname, setUname] = useState("--");
  const [ustat, setUstat] = useState("--");
  const [ustate, setUstate] = useState("--");
  const [ubank, setUbank] = useState("--");
  const [udet, setUdet] = useState("--");
  const [usdt, setUsdt] = useState("--");
  const [udur, setUdur] = useState("--");
  const [upass, setUpass] = useState("--");
  const [ucpu, setUcpu] = useState("--");
  const [laststart, setLaststart] = useState("--");
  const [upay, setUpay] = useState("0");
  const [utos, setUtos] = useState("--");
  const [usci, setUsci] = useState("--");
  const [ureg, setUreg] = useState("--");
  const [uclass, setUclass] = useState("--");
  const [utype, setUtype] = useState("--");
  const [uip, setUip] = useState("--");
  const [ustanding, setUstanding] = useState("--");
  //const [fact, setFact] = useState("");
  const [ucb, setUcb] = useState("1");
  const [uerr, setUerr] = useState("Connecting to Service...");


  //const notify = () => toast.success("Hello There!");

  
  function getDat(res) {

    var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
    setUerr('0');

    var tmpstat = tmpstr.split("!")[0];
    var tmpustanding = tmpstr.split("!")[5];
    var tmputos = tmpstr.split("!")[7];
     var tmputype = tmpstr.split("!")[8];
    var tmpureg = tmpstr.split("!")[10];
    var tmpuclass = tmpstr.split("!")[12];
    var tmpusci = tmpstr.split("!")[14];
    var tmpdur = tmpstr.split("!")[2];
    var tmpuip = tmpstr.split("!")[17];
    
    var tmpucb = tmpstr.split("!")[19];
    var tmplaststart = tmpstr.split("!")[20];

    var tmpbank = tmpstr.split("!")[1];
    var tmpdet = tmpstr.split("!")[3];
    var tmpstate = tmpstr.split("!")[4];
    var tmpusdt = tmpstr.split("!")[18];
      var tmpucpu = tmpstr.split("!")[13];


    var tmp = tmpstr.split("!<<<<")[1];
    var tmpupass = tmp.substring( tmp.indexOf("<<<<") + 1,tmp.lastIndexOf(">>>>") );

    setUstat(tmpstat);
    setUstate(tmpstate);
    setUbank(tmpbank);
    setUdet(tmpdet);
    setUstanding(tmpustanding);
    setUtos(tmputos);
    setUsci(tmpusci);
    setUreg(tmpureg);
    setUclass(tmpuclass);
    setUtype(tmputype);
    setUip(tmpuip);
   //setFact(res); ///////////////////////////
  // alert(res);
   setUcb(tmpucb);
   setUsdt(tmpusdt);
   setUpass(tmpupass);
   setUcpu(tmpucpu);
    setUdur(tmpdur);
    setLaststart(tmplaststart)
  };



  useEffect(() => {

    async function statusButton2() {
   //alert("app-SB");

      const user = await Auth.currentAuthenticatedUser();
     var tmpuname = user.attributes.email;
     setUname(user.attributes.email);
      //alert(tmpuname);
    
       var raw = JSON.stringify({"uname": tmpuname,"act": "status_"+ appConfig.apiname});

     var myInit = {
      body: raw,
      headers: {
        'Content-Type': 'application/json'}, 
        redirect: 'follow',
    };
    API.post(apiName, path, myInit)
     .then(response => getDat(response) )
    //.catch((error) => {
     // goError(error)
      //alert(appConfig.appname + " MAIN STATUS API ERROR: " + error.message + ' ' + error.response) 
      .catch((error) => goError("Connection Error(1): " + error.message))

     };




    //checkUser();
    statusButton2()
  }, []);



 
  useEffect(() => {
    //document.title = "VG Servers Portal"
  document.title = appConfig.webtitle
  }, []);




  


  function goError(err) {

    setUerr(err);
   //alert(appConfig.appname + "ERROR!! (214) " + err);


  };

var fact2 = "";

if (ucb === "1") {
  var tmpHideCB = true;
} else { 


if (ureg === 'not set'){

  tmpHideCB = true


} else {

  tmpHideCB = false
}
}


if (uerr === '0') {
 var hideErr = true;
 var hideBody = false;
 var hideSpin = true;
} else {
  hideErr = false;
  hideBody = true;

  if (uerr === 'Connecting to Service...') {
    hideSpin = false;
    var eclass = "App-stopped";
    var dispuerr = "";
  } else {
    hideSpin = true
    eclass = "App-processing";
    dispuerr = uerr;
  }

};






//<div className="App" style={{ backgroundImage: `url(${backgroundA})`, backgroundRepeat:"no-repeat",backgroundSize:"contain",backgroundPosition:"center"  }} >

  return (



    <div className="App" style={{ paddingTop: "15px",paddingBottom: "15px" ,minWidth:  '100%' ,  minHeight:'100vh', width: 'fit-content' , height: 'fit-content', alignItems:'center',  justifyContent: 'center',  textAlign: 'center',  display: 'flex', backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center"  ,backgroundAttachment: 'fixed'  }}>
   

 <Flex
 direction="column"
 textAlign="center"
 alignItems="center"
 gap="15px"
 >
      
      <div className = "App" style={{  display: "inline",  fontWeight: "normal",color: "white",  border: "4px" , borderStyle: "solid" , borderColor: "black" ,  maxWidth: '400px', minWidth: '400px'   ,textAlign: "center" , minHeight: appConfig.minHeight }} >
      
      
      <span className = 'App-title' style={{fontSize: '30px'}}>{appConfig.appname}</span><br></br>
      <span className = 'App-user' style={{ fontSize: '23px'  }}>{uname}</span><br></br>



<span hidden={hideErr} className = {eclass}>
<Flex  
direction="column"
textAlign="center"
alignItems="center"
gap='0px'
paddingTop='10px'
minHeight={appConfig.minHeightB}
> 
<br></br><br></br>
<span style={{fontSize: '23px'}}>
{dispuerr}
</span>
<span hidden={hideSpin} >
<Flex 
      
      direction="column"
      //marginLeft='10px'
      //marginRight='10px'
     // wrap='wrap'
     // gap='10px'
 //     marginTop='15px'
  //    marginBottom='calc(15px + 6px)'
  paddingTop="15px"
  paddingBottom="9px"
     textAlign="center"
     alignItems="center"
     justifyContent= "center"
     gap = '0px'
     minHeight={appConfig.minHeightD }>     
      
      <img src={logo} className="App-logo" alt="logo" />
      
      </Flex>
      </span>

</Flex>
</span>


<span hidden={hideBody} >
      <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
        gap='0'
        minHeight={appConfig.minHeightB}
        >

  
     

      <StatusTime value={uname} utos={utos} upass={upass} laststart={laststart} ucpu={ucpu} ustat={ustat} ustate={ustate} usdt={usdt} ubank={ubank} udur={udur} udet={udet} upay={upay} ustanding={ustanding} ureg={ureg} uclass={uclass} uip={uip} utype={utype} fact={fact2} key={fact2} key2={fact2}  />

     
    
            
              <><Body uname={uname} utos={utos} upay={upay} ustanding={ustanding} usci={usci} uip={uip} key={fact2} key2={fact2}  funct={setUstanding} funct2a={setUpay} funct2={setUtos} funct3={setUreg} funct4={setUclass} funct4a={setUtype} funct5={setUip} funct6={setUcb} ustatus={ustat} ureg={ureg} uclass={uclass} utype={utype} ucb = {ucb} /></>

      </Flex>
      </span>     

     
    

              <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         marginTop={appConfig.bGap}
         //gap = {appConfig.bGap}
        >
            <button className = 'SO-button' style={{ border: "none",display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={signOut}>SIGN OUT</button>
           </Flex>
           
           

      

      </div>
      

     

      <div className = 'chat_bot2'>
        <div hidden = {tmpHideCB} >
        <Chatbot config={config} actionProvider={ActionProvider}  messageParser={MessageParser} uname={uname} />
        </div>
        </div>
        </Flex>


        <div hidden = {tmpHideCB} className = 'chat_bot'>
        
        <Chatbot config={config} actionProvider={ActionProvider}  messageParser={MessageParser} uname={uname} />
        </div>


        
       

      <ToastContainer
      //position="top-right"
      //progressClassName="toastProgress"
      //bodyClassName="toastBody"
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      //theme="dark"
      //theme="light"
      theme="colored"
      icon={true}
      >
      </ToastContainer>
  


    </div>
    

    
  );
}
 
//"#e6ac00" and #ffb300 old help button color

export default withAuthenticator(App);

